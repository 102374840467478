@import 'variables';

$prefix: $antPrefix + -tree;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  .#{$prefix}-list {

    .#{$prefix}-checkbox:hover,
    .#{$prefix}-checkbox:focus {
      .#{$prefix}-checkbox-inner {
        border-color: $primaryColor;
      }
    }

    .#{$prefix}-checkbox-indeterminate {
      .#{$prefix}-checkbox-inner {
        &::after {
          background-color: $primaryColor;
        }
      }
    }

    .#{$prefix}-checkbox-checked {
      .#{$prefix}-checkbox-inner {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }

    .#{$prefix}-node-content-wrapper.#{$prefix}-node-selected {
      background-color: $primaryColor;
      color: #fff;
    }
  }
}