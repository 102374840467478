@import 'variables';

$prefix: $antPrefix + -page-header;

.#{$prefix} {
  color: $textColor;
  background: $componentBackground;
  padding: 8px 16px 0;
  border-radius: 16px 0 0 0;

  &>.#{$antPrefix}-breadcrumb {
    display: none;
  }

  &-heading-title,
  &-back-button,
  &>.#{$antPrefix}-breadcrumb a:not(:hover),
  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    color: $textColor;
  }

  &>.#{$antPrefix}-breadcrumb>span:last-child a {
    cursor: auto;
  }

  &-back-button .anticon-arrow-left {
    color: $primaryColor;
  }

  &-heading-title {
    color: $textBlack;
    font-family: 'Enedis';
    font-weight: 700;
    font-size: 24px;
  }
}