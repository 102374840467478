@import 'variables';

$prefix: $antPrefix + -tabs;

.#{$prefix} {
  color: $textColor;

  >.#{$prefix}-nav::before,
  &-bar {
    border-bottom-color: $bodyBackground;
  }

  &-content {
    color: $textColor;
  }

  &-nav {
    background: $componentBackground;
    padding: 16px 24px;
    margin: 0;
  }

  &-nav .#{$prefix}-tab {
    &-active {
      .#{$prefix}-tab-btn {
        color: $primaryColor;
      }
    }

    &:hover {
      color: $primaryColor;
    }

    &-disabled,
    &-disabled:hover {
      color: $disabledColor;
    }
  }

  &-ink-bar {
    background-color: $primaryColor;
  }
}

.#{$prefix}-top>.#{$prefix}-nav::before,
.#{$prefix}-bottom>.#{$prefix}-nav::before,
.#{$prefix}-top>div>.#{$prefix}-nav::before,
.#{$prefix}-bottom>div>.#{$prefix}-nav::before {
  border-bottom: none;
}

.source-post-tabs {
  background: $componentBackground;
  flex: 4 1 auto;
}