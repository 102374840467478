@import 'variables';

.ant-layout-footer {
  background: $grey200;
  box-shadow: none;
  color: $textHolder;
  text-align: right;
  a {
    color: $textHolder;
  }
}
