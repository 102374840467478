@import 'variables';

$prefix: $antPrefix + -timeline;

.#{$prefix} {
    .#{$prefix}-item {
        .#{$prefix}-item-head-green {
            color: $addColor;
            border-color: $addColor;
        }

        .#{$prefix}-item-head-blue {
            color: $primaryColor;
            border-color: $primaryColor;
        }

        .#{$prefix}-item-head-red {
            color: $secondaryColor;
            border-color: $secondaryColor;
        }

        .#{$prefix}-item-label {
            font-style: italic;
        }
    }
}

.hide-after-5 .#{$prefix}-item:nth-child(n+6) {
    display: none;
}

.dashboard-timeline-col {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px;
}