@import 'variables';

$prefix: $antPrefix + -select;

.#{$prefix}-dropdown {
  background-color: $contrastBackground;

  .#{$prefix}-item {
    &:not(.#{$prefix}-item-option-disabled) {
      color: $textColor;

      &.#{$prefix}-item-option-selected {
        color: $primaryColor;
        background-color: $itemHoverBackground;
      }

      &:hover {
        background-color: $itemHoverBackground;
      }
    }
  }
}

%disabled {
  &.#{$prefix}-disabled {
    .#{$prefix}-selector {
      color: $disabledColor;
      background: $bodyBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-arrow {
      color: $disabledColor;
    }
  }
}

.#{$prefix}-multiple {
  &:not(.#{$prefix}-disabled) {
    .#{$prefix}-selector {
      color: $textColor;
      background-color: $componentBackground;
      border-color: $borderColor;
    }

    .#{$prefix}-selection-item {
      background: $itemHoverBackground;
      border-color: $itemHoverBackground;
    }

    .#{$prefix}-selection-item-remove {
      color: $contrastBackground;

      &:hover {
        color: $primaryColor;
      }
    }
  }

  @extend %disabled;
}

.#{$prefix} {
  &.#{$prefix}-single:not(.#{$prefix}-customize-input) {
    border-radius: 8px;

    .#{$prefix}-selector {
      border-radius: 8px;
    }

    &:not(.#{$prefix}-disabled) {
      .#{$prefix}-selector {
        color: $textColor;
        background-color: $componentBackground;
        border-color: $borderColor;
      }

      .#{$prefix}-arrow {
        color: $textColor;
      }

      &.#{$prefix}-open,
      &:hover,
      &:focus,
      &:focus-within {
        .#{$prefix}-selector {
          color: $primaryColor;
          border-color: $primaryColor;

          .#{$prefix}-selection-item {
            opacity: 1;
          }
        }

        .#{$prefix}-arrow {
          color: $primaryColor;
        }
      }
    }

    @extend %disabled;
  }

  &-item-option-active:not(.#{$prefix}-item-option-disabled) {
    background-color: $itemHoverBackground;
  }
}