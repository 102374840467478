@import 'variables';

$prefix: $antPrefix + -menu;
$darkClass: $prefix + -dark;

$activeArrowClass: '.#{$prefix}-item:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title > .#{$prefix}-submenu-arrow, .#{$prefix}-item:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-item-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-active > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-open > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-selected > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow, .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-title:hover > .#{$prefix}-submenu-arrow';
$activeLinkClass: '.#{$prefix}-item:hover,.#{$prefix}-item-active,.#{$prefix}-submenu-active,.#{$prefix}-submenu-open,.#{$prefix}-submenu-selected,.#{$prefix}-submenu-title:hover';
$menuBorderRight: '.#{$prefix}-vertical .#{$prefix}-item::after, .#{$prefix}-vertical-left .#{$prefix}-item::after, .#{$prefix}-vertical-right .#{$prefix}-item::after, .#{$prefix}-inline .#{$prefix}-item::after';

@mixin selectedItemStyle($prefixClass) {
  &.#{$prefixClass}:not(.#{$prefix}-horizontal) .#{$prefix}-item-selected {
    color: $primaryColor;
    background-color: $itemHoverBackground;
  }

  &.#{$prefixClass} .#{$prefix}-item-selected {

    &>a,
    &>a:hover {
      color: $primaryColor;
    }
  }
}

%baseMenu {
  #{$activeLinkClass} {
    &>a {
      color: $primaryColor;
    }
  }

  color: $textColor;
  background: $componentBackground;
}

%lightMenu {
  @extend %baseMenu;

  .#{$prefix}-item:hover,
  .#{$prefix}-item,
  .#{$prefix}-item-active,
  .#{$prefix}:not(.#{$prefix}-inline) .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  .#{$prefix}-item:active,
  .#{$prefix}-submenu-title:active {
    background-color: transparent;
  }
}

.#{$prefix}-sub.#{$prefix}-inline>.#{$prefix}-item {
  color: $textColor;

  &.#{$prefix}-item-active {
    color: $primaryColor;
  }
}

.#{$prefix}-inline,
.#{$prefix}-vertical {
  border-right: transparent;
}

.#{$prefix}-item a {
  color: $textColor;
}

.#{$prefix}-item a:hover {
  color: $secondaryHover;
}

.#{$prefix} {
  @extend %lightMenu;
  @include selectedItemStyle($prefix);
}

.#{$prefix}-submenu>.#{$prefix} {
  color: $textColor;
  background: $contrastBackground;
}

#{$menuBorderRight} {
  border-color: $primaryColor;
}

.#{$prefix}-item-selected {
  color: $primaryColor;
}

.#{$prefix}-submenu-inline>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow {

  &::before,
  &::after {
    background-image: linear-gradient(to right, $textColor, $textColor);
  }
}

.#{$prefix}-submenu-selected>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu-open>.#{$prefix}-submenu-title .#{$prefix}-submenu-arrow,
.#{$prefix}-submenu>.#{$prefix}-submenu-title:hover .#{$prefix}-submenu-arrow {

  &::before,
  &::after {
    background-image: linear-gradient(to right, $primaryColor, $primaryColor);
  }
}

.#{$darkClass} {
  @extend %baseMenu;

  &.#{$prefix} {
    background: $componentBackground;
  }

  .#{$prefix}-inline.#{$prefix}-sub,
  &.#{$prefix}-sub {
    background: transparent;
  }

  .#{$prefix}-item:hover,
  .#{$prefix}-item-active,
  .#{$prefix}-submenu-active,
  .#{$prefix}-submenu-open,
  .#{$prefix}-submenu-selected,
  .#{$prefix}-submenu-title:hover {
    color: $primaryColor;
  }

  @include selectedItemStyle($darkClass);
}

.logo-phileas {
  height: 24px;
  position: relative;
  padding: 24px 24px 48px 24px;
  overflow: hidden;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;

  img {
    display: inline-block;
    height: 24px;
    vertical-align: middle;
  }
}

.aside-menu {
  overflow: initial;

  .menu-list {
    background: none !important;
    padding: 16px 0;

    .#{$prefix}-item {
      .#{$prefix}-title-content {
        a {
          color: #fff !important;
        }
      }

      &:after {
        border-color: $blueEnedis200  !important;
      }

      &:hover {
        .#{$prefix}-title-content {
          a {
            color: $blueEnedis300  !important;
          }
        }
      }
    }

    .#{$prefix}-item-selected {
      background-color: $blueEnedis400  !important;

      &:hover {
        .#{$prefix}-title-content {
          a {
            color: $textWhite  !important;
          }
        }
      }
    }
  }

  .bottom-part {
    display: flex;
    flex-direction: column;

    .user-profile {
      align-items: center;
      display: flex;
      flex-direction: row;
      color: $textWhite;
      margin: 4px 0 8px 0;
      padding: 24px;

      Button {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: auto;
        text-align: left;

        .#{$antPrefix}-typography {
          color: $textWhite;
        }
      }

      .#{$antPrefix}-avatar {
        background: $blueEnedis400;
        color: $blueEnedis300;
      }

      .name {
        font-weight: 600;
      }
    }

    .divider {
      padding: 0 24px;

      .#{$antPrefix}-divider {
        background: $blueEnedis400;
        margin: 0;
      }
    }
  }

  .#{$antPrefix}-layout-sider-children {
    display: flex;
    flex-direction: column;
  }

  .menu-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
}