@import 'variables';

$prefix: $antPrefix + -layout;

.#{$prefix} {
  background: $bodyBackground;

  &-header,
  &-sider,
  &-sider-children,
  &-sider-trigger {
    background: $clientColor;
  }
}

.layout-bg {
  .main-content {

    .#{$prefix}-content {

      >.#{$antPrefix}-row:first-child:not(.dashboard-row):not(.post-data-row) {
        background: $grey100;
        border-radius: 16px;
        padding: 12px;
        margin-bottom: 24px;
      }
    }
  }
}