@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
$prefix: $antPrefix + -typography;

span,
.ant-layout-footer {
  font-family: 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.ant-page-header-heading-title {
  color: $textBlack;
  font-family: 'Enedis', sans-serif !important;
  font-weight: bold;
}

h1 {
  font-size: 38px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
