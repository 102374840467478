$antPrefix: ant;

$clientColor: var(--clientColor);
$bodyBackground: var(--bodyBackground);
$primaryColor: var(--primaryColor);
$addColor: var(--addColor);
$primaryHover: var(--primaryHover);
$primaryColorA04: var(--primaryColorA04);
$primaryColorA07: var(--primaryColorA07);
$primaryContrast: var(--primaryContrast);
$secondaryColor: var(--secondaryColor);
$secondaryHover: var(--secondaryHover);
$tabsGray: var(--tabsGray);
$componentBackground: var(--componentBackground);
$menuDropdownBackground: var(--menuDropdownBackground);
$subMenuBackground: var(--subMenuBackground);
$contrastBackground: var(--grey100);
$textColor: var(--textColor);
$textHolder: var(--textHolder);
$textColorSecondary: var(--textColorSecondary);
$textColorInvert: var(--textColorInvert);
$textColorHover: var(--textColorHover);
$textColorA015: var(--textColorA015);
$textColorA075: var(--textColorA075);
$borderColor: var(--borderColor);
$borderColorA06: var(--borderColorA06);
$disabledColor: var(--disabledColor);
$disabledColorA04: var(--disabledColorA04);
$disabledColorA06: var(--disabledColorA06);
$itemActiveBackground: var(--itemActiveBackground);
$itemHoverBackground: var(--itemHoverBackground);
$itemActiveColor: var(--itemActiveColor);
$itemHoverColor: var(--itemHoverColor);
$logo: var(--logo);
$logoSmall: var(--logoSmall);
$errorColor: var(--errorColor);
$error100: var(--error100);
$errorA08: var(--errorA08);
$pickerDropdown: var(--pickerDropdown);
$opacitySVG: var(--opacitySVG);
$opacitySpin: var(--opacitySpin);
$textBlack: var(--textBlack);
$textWhite: var(--textWhite);
$blueEnedis200: var(--blueEnedis200);
$blueEnedis300: var(--blueEnedis300);
$blueEnedis400: var(--blueEnedis400);
$blueEnedis500: var(--blueEnedis500);
$blueEnedis600: var(--blueEnedis600);
$blueEnedis700: var(--blueEnedis700);
$greenEnedis200: var(--greenEnedis200);
$greenEnedis300: var(--greenEnedis300);
$greenEnedis400: var(--greenEnedis400);
$greenEnedis500: var(--greenEnedis500);
$greenEnedis600: var(--greenEnedis600);
$greenEnedis700: var(--greenEnedis700);
$grey50: var(--grey50);
$grey75: var(--grey75);
$grey100: var(--grey100);
$grey200: var(--grey200);
$grey300: var(--grey300);
$grey400: var(--grey400);
$grey500: var(--grey500);
$grey600: var(--grey600);
$grey700: var(--grey700);
$grey800: var(--grey800);
$grey900: var(--grey900);
$greyMuted: var(--greyMuted);
