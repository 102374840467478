@import 'variables';

$prefix: $antPrefix + -card;

.#{$prefix} {
  background: $componentBackground;
  color: $textColor;

  &-head,
  &-meta-title,
  &-meta-description {
    color: $textColor;
  }

  &-head {
    border-bottom-color: $borderColor;
  }

  &-bordered {
    border-color: $borderColor;
  }
  .#{$prefix}-cover  {
    > img {
      aspect-ratio: 1/1;
    }
  }
}

.dashboard-row {
  .dashboard-card {
    border-radius: 12px;

    .#{$prefix}-head {
      border: 0;

      .#{$prefix}-head-title {
        color: $primaryColor;
        position: relative;
        padding: 16px 0 8px;

        &::after {
          content: '';
          background: $primaryColor;
          width: 80px;
          height: 4px;
          position: absolute;
          left: 0;
          bottom: 0;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
}

.card-text-description {
  font-style: italic;
  font-weight: bold;
}   

.card-text-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}   

.card-show{
  min-width: 50vw;
  width: 50vw;
   @media (max-width: 768px) {
    min-width: 90vw;
    width: 90vw;
 }
}

.card-title {
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}