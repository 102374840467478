@import 'variables';

.schema-wrapper {
  background-color: #fff;
  padding: 10px;
  flex: 1;
  flex-direction: column;

  .schema-header {
    justify-content: end;
    width: 100%;
    background-color: $grey100;
    padding: 12px;
    border-radius: 16px;
  }

  .schema-top-menu {
    margin-bottom: 16px;
    padding: 0 8px;
  }

  >.#{$antPrefix}-col {
    margin: 16px 0;
    overflow: auto;
    max-width: 100%;
    flex: 1 0;

    .schema-column {
      padding: 16px 24px;
      margin: 16px;
    }

    .schema-line {
      position: absolute;
      opacity: 0.5;
    }

    .schema-table {

      td,
      th {
        text-align: center;
        border: 1px solid $grey100;
      }
    }

    .transformer-row {
      flex-wrap: nowrap;
      min-width: 80vw;
    }

    .transformer-wrapper {
      display: inline-flex;
      flex-wrap: nowrap;
      margin: 0 25px 50px 0;

      .transformer-title {
        width: 30%;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 8px 8px 0px;
        margin: 0 5%;
      }

      .schema-table {
        width: 65%;

        td[rowspan="3"] {
          background: $grey100;
        }
      }
    }

    .demirame-wrapper {

      .schema-table {
        width: 95%;
        margin: auto -1px auto auto;
      }

      .schema-table:not(.at-table) {
        tr>td:first-child {
          border: 0;
          padding: 4px 8px 4px 0;

          >span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .depart-title {

        >span {
          border-radius: 0px 8px 8px 0px;
        }
      }

      .at-wrapper-entry, .at-wrapper-exit {
        position: relative;

        .at-popover-btn {
          position: absolute;
          left: 6px;
          font-size: 12px;
          display: inline-block;
          background: #8357f2;
          padding: 1px 5px;
          border-radius: 4px;
          color: #fff;
          cursor: pointer;
          
          .anticon {
            margin-right: 4px;
          }
        }
      }

      .at-wrapper-entry {
        .at-popover-btn {
          top: -24px;
        }
      }

      .at-wrapper-exit {
        .at-popover-btn {
          top: -4px;
        }
      }
    }
  }

  .schema-toolbar-wrapper {
    width: 100%;
    padding: 8px;

    .#{$antPrefix}-btn {
      margin: 5px;
    }
  }

  .schema-edit-elements {
    padding: 0 8px 8px;
    gap: 24px;

    .schema-card-wrapper {
      max-width: 100%;
      overflow-x: auto;

      >.#{$antPrefix}-row {
        flex-wrap: nowrap;
      }

      .edit-transformer-card,
      .edit-column-card,
      .edit-demirame-card,
      .edit-AT-card,
      .edit-depart-card {
        >.#{$antPrefix}-card-head {
          padding: 0 16px;
          min-height: unset;

          .#{$antPrefix}-card-head-title,
          .#{$antPrefix}-card-extra {
            padding: 8px 0;
          }
        }
      }

      .edit-transformer-card {
        border-radius: 0px 8px 8px 0px;

        >.#{$antPrefix}-card-body {
          display: none;
        }

        >.#{$antPrefix}-card-head {
          border: 0;
        }
      }

      .edit-column-card,
      .edit-demirame-card {
        width: 150px;
        margin-right: 24px;

        >.#{$antPrefix}-card-head {
          border: 0;
          border-radius: 0px 8px 8px 0px;
        }

        >.#{$antPrefix}-card-body {
          border: 1px dashed $borderColor;
          border-top: 0;
          border-radius: 0px 8px 8px 8px;
          padding: 8px;

          .edit-demirame-card+.edit-demirame-card {
            margin-top: 8px;
          }

          .edit-demirame-card {
            width: 100%;

            >.#{$antPrefix}-card-body {
              padding: 8px;

              p {
                margin: 0;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
              }
            }
          }

          .edit-depart-card {
            width: 100%;

            >.#{$antPrefix}-card-body {
              padding: 8px;

              p {
                margin: 0;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
              }
            }
          }

          .edit-depart-card:not(:last-child),
          .edit-AT-card:not(:last-child),
          .edit-demirame-card:not(:last-child) {
            margin-bottom: 8px;
          }

          .edit-AT-card {
            border: 1px solid $borderColor;

            >.#{$antPrefix}-card-body {
              display: none;
            }

            >.#{$antPrefix}-card-head {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.schema-table.at-table {
  .at-name {
    padding: 4px 16px 4px 0;
    border: 0;

    >span {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden; 
      text-overflow: ellipsis;
      padding: 2px 6px;
      color: #fff;

      .anticon {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }

  th {
    padding: 4px 10px;
  }

  tr:last-child {
    td {
      text-align: center;
    }
  }
}